.Hotspot {
  background: #dbdbdb;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  height: 150px;
  padding: 8px;
  position: relative;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.5s ease-in-out;
}

.Hotspot:not([data-visible]) {
  background: transparent;
  border: 4px solid #fff;
  box-shadow: none;
  height: 32px;
  pointer-events: none;
  width: 32px;
}

.Hotspot:focus {
  border: 4px solid rgb(0, 128, 200);
  height: 100px;
  outline: none;
  width: 32px;
  background: #aaaaaa;
}

.Hotspot:focus .HotspotAnnotation {
  background: #7a4191;
  color: #fff;
  transform: rotate(6deg);
}

.Hotspot > * {
  opacity: 1;
  transform: translateY(-50%);
}

.HotspotAnnotation {
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: #406580;
  background: #ffefe4;
  display: block;
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  left: calc(100% + 1em);
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  width: max-content;
  transform: rotate(-10deg);
  transition: all 0.5s ease-in-out;
}

.Hotspot:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition:
    transform 0.3s,
    opacity 0.3s;
}
